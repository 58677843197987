import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import sendToGtm from './sendToGtm';

const gtmId: string = process.env.REACT_APP_GTM_ID || '';
console.log('gtmId:', gtmId);

const tagManagerArgs = {
  gtmId: gtmId,
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals(sendToGtm);
