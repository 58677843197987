import { Metric } from 'web-vitals';

const sendToGtm = (metric: Metric) => {
  const { id, name, value } = metric;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'web_vitals',
    event_category: 'Web Vitals',
    event_action: name,
    event_label: id,
    value: Math.round(name === 'CLS' ? value * 1000 : value), // Ensure CLS is multiplied by 1000
  });
};

export default sendToGtm;
